import { Injectable } from '@angular/core';
import { Presence, PresenceFilters, RecoveryDetailsDTO } from 'app/_models/planning';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from './../_helpers/listResult.interface';
import { LaravelAbsenceService } from './laravel/laravel-absence.service';

@Injectable({ providedIn: "root" })
export class AbsenceService {
  constructor(private laravelAbsenceService: LaravelAbsenceService) {}

  public getAbsence(
    presenceId: number,
    include?: string | string[]
  ): Observable<Presence> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelAbsenceService
      .getAbsenceById(presenceId, includes)
      .pipe(map((dto) => new Presence(dto)));
  }

  public getAbsences(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filters?: PresenceFilters,
    include?: string | string[]
  ): Observable<ListResult<Presence>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelAbsenceService
      .getAbsences(page, perPage, order, direction, filters, includes)
      .pipe(
        map((response) => {
          return {
            data: response.data.map((dto) => new Presence(dto)),
            total: response.total,
          };
        })
      );
  }

  public addRecovery(
    presenceId: number,
    plannedUnitId: number,
    details: RecoveryDetailsDTO
  ): Observable<Presence> {
    return this.laravelAbsenceService
      .addRecovery(presenceId, plannedUnitId, details)
      .pipe(map((dto) => new Presence(dto)));
  }

  public cancel_recovery(
    presenceId: number
  ): Observable<Presence> {
    return this.laravelAbsenceService
      .cancel_recovery(presenceId)
      .pipe(map((dto) => new Presence(dto)));
  }
}
