import { Participant } from "./../_models/planning";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Registration, RegistrationFilter } from "./../_models/registration";
import { LaravelRegistrationService } from "./laravel/laravel-registration.service";
import { ListResult } from "../_helpers/listResult.interface";

@Injectable({ providedIn: "root" })
export class RegistrationService {
  constructor(private laravelRegistrationService: LaravelRegistrationService) {}

  public addRegistration(registration: Registration): Observable<Registration> {
    return this.laravelRegistrationService
      .createRegistration(registration.toDTO())
      .pipe(
        map((dto) => {
          return new Registration(dto);
        })
      );
  }

  public updateRegistration(
    registration: Registration
  ): Observable<Registration> {
    return this.laravelRegistrationService
      .updateRegistration(registration.objectId, registration.toDTO())
      .pipe(
        map((dto) => {
          return new Registration(dto);
        })
      );
  }

  public deleteRegistration(
    registration: Registration
  ): Observable<Registration> {
    return this.laravelRegistrationService
      .deleteRegistration(registration.objectId)
      .pipe(
        map(() => {
          return registration;
        })
      );
  }

  public deleteAllArchived(): Observable<any> {
    return this.laravelRegistrationService.deleteAlArchived();
  }

  public restoreRegistration(
    registration: Registration
  ): Observable<Registration> {
    return this.laravelRegistrationService
      .restoreRegistration(registration.objectId)
      .pipe(
        map((restored) => {
          return new Registration(restored);
        })
      );
  }

  public getRegistration(registrationId: number): Observable<Registration> {
    return this.laravelRegistrationService
      .getRegistrationById(registrationId)
      .pipe(map((dto) => new Registration(dto)));
  }

  public countRegistrations(): Observable<number> {
    return this.laravelRegistrationService.countRegistrations();
  }

  public getRegistrations(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter?: RegistrationFilter,
    include?: string | string[]
  ): Observable<ListResult<Registration>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelRegistrationService
      .getRegistrations(page, perPage, order, direction, filter, includes)
      .pipe(
        map((response) => {
          return {
            data: response.data.map((dto) => new Registration(dto)),
            total: response.total,
          };
        })
      );
  }

  public planRegistration(
    registrationId: number,
    participant: Participant
  ): Observable<Participant> {
    return this.laravelRegistrationService
      .planRegistration(registrationId, participant.toDTO())
      .pipe(map((response) => new Participant(response)));
  }
}
