import { AbstractControl } from '@angular/forms';

import { formatDateForBackend } from '../_helpers/utils';
import { Address, AddressDTO, CommonObject, CommonObjectDTO } from './common-object';
import { Employee, EmployeeDTO } from './employee';
import { Org, OrgDTO } from './org';
import { Participant, ParticipantDTO, PlannedCourse, PlannedCourseDTO } from './planning';

export type RegistrationType = "private" | "org";
export interface RegistrationDTO extends CommonObjectDTO {
  type: RegistrationType;
  name: string;
  surname: string;
  org_id?: number;
  org?: OrgDTO;
  employee_id?: number;
  employee?: EmployeeDTO;
  planned_course_id?: number;
  planned_course?: PlannedCourseDTO;
  participant_id?: number;
  participant?: ParticipantDTO;
  address?: string;
  phone?: string;
  cellphone?: string;
  email?: string;
  birthdate?: Date;
  birthplace?: string;
  birthprovince?: string;
  identifier?: string;
  note?: string;
  org_data?: OrgDataDTO;
  registration_date?: Date;
  order_reference: string;
  price: number;
}

export class Registration extends CommonObject {
  type: RegistrationType;
  name: string;
  surname: string;
  address?: string;
  phone?: string;
  cellphone?: string;
  email?: string;
  birthdate?: Date;
  birthplace?: string;
  birthprovince?: string;
  identifier?: string;
  VAT?: string;
  note?: string;
  org?: Org;
  employee?: Employee;
  plannedCourse?: PlannedCourse;
  participant?: Participant;
  orgData?: OrgData;
  registrationDate?: Date;
  orderReference: string;
  price: number;

  constructor(dto: RegistrationDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.type = dto.type;
      this.name = dto.name;
      this.surname = dto.surname;
      this.address = dto.address;
      this.phone = dto.phone;
      this.cellphone = dto.cellphone;
      this.email = dto.email;
      this.birthplace = dto.birthplace;
      this.birthprovince = dto.birthprovince;
      if (dto.birthdate) {
        this.birthdate = new Date(dto.birthdate);
      }
      this.identifier = dto.identifier;
      this.note = dto.note;

      if (dto.org) {
        this.org = new Org(dto.org);
        this.addLoadedRelation("org");
      }
      if (dto.employee) {
        this.employee = new Employee(dto.employee);
        this.addLoadedRelation("employee");
      }
      if (dto.planned_course) {
        this.plannedCourse = new PlannedCourse(dto.planned_course);
        this.addLoadedRelation("planned_course");
      }
      if (dto.participant) {
        this.participant = new Participant(dto.participant);
        this.addLoadedRelation("participant");
      }
      if (dto.org_data) {
        this.orgData = new OrgData(dto.org_data);
      }
      if (dto.registration_date) {
        this.registrationDate = new Date(dto.registration_date);
      }
      this.orderReference = dto.order_reference;
      this.price = dto.price;
    }
  }

  public toDTO(): RegistrationDTO {
    let result: RegistrationDTO = <RegistrationDTO>super.toDTO();
    Object.assign(result, {
      type: this.type,
      name: this.name,
      surname: this.surname,
      address: this.address,
      phone: this.phone,
      cellphone: this.cellphone,
      email: this.email,
      birthplace: this.birthplace,
      birthprovince: this.birthprovince,
      birthdate: formatDateForBackend(this.birthdate),
      registration_date: formatDateForBackend(this.registrationDate),
      identifier: this.identifier,
      VAT: this.VAT,
      note: this.note,
      org_id: this.org ? this.org.objectId : null,
      employee_id: this.employee ? this.employee.objectId : null,
      planned_course_id: this.plannedCourse
        ? this.plannedCourse.objectId
        : null,
      participant_id: this.participant ? this.participant.objectId : null,
      org_data: this.orgData,
      order_reference: this.orderReference,
      price: this.price
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: Registration
  ): Registration {
    const formModel = formGroup.value;

    let registration: Registration = new Registration(null);
    registration.type = formModel.type;
    registration.name = formModel.name;
    registration.surname = formModel.surname;
    registration.phone = formModel.phone;
    registration.cellphone = formModel.cellphone;
    registration.email = formModel.email;
    registration.birthdate = formModel.birthdate
      ? new Date(formModel.birthdate)
      : null;
    registration.birthplace = formModel.birthplace;
    registration.birthprovince = formModel.birthprovince;
    registration.registrationDate = formModel.registrationDate
      ? new Date(formModel.registrationDate)
      : null;
    registration.identifier = formModel.identifier
      ? formModel.identifier.toUpperCase()
      : null;
    registration.VAT = formModel.VAT ? formModel.VAT.toUpperCase() : null;
    registration.note = formModel.note;
    registration.plannedCourse = formModel.plannedCourse;
    registration.orgData = formModel.orgData;
    registration.orderReference = formModel.orderReference;
    registration.price = formModel.price;
    if (original) {
      registration.objectId = original.objectId;
      registration.loadedRelations = original.loadedRelations;
    }
    return registration;
  }

  get fullName() {
    return `${this.name} ${this.surname}`;
  }

  get archived(): boolean {
    return this.deletedAt != null;
  }
}

export interface RegistrationFilter {
  includePlanned?: boolean;
  plannedCourseId?: number;
  courseSearchQuery?: string;
  employeeSearchQuery?: string;
  onlyArchived?: boolean;
}

export interface OrgDataDTO {
  name: string;
  address: AddressDTO;
  phone?: string;
  email?: string;
  VAT?: string;
  identifier?: string;
}

export class OrgData {
  name: string;
  address: Address;
  phone?: string;
  email?: string;
  VAT?: string;
  identifier?: string;

  constructor(dto: OrgDataDTO) {
    if (dto) {
      this.name = dto.name;
      this.address = dto.address;
      this.phone = dto.phone;
      this.email = dto.email;
      this.VAT = dto.VAT;
      this.identifier = dto.identifier;
    }
  }
}
